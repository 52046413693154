angular.module('FactoriesModule')
  .factory('AuthenticationFactory', function ($window, $cookies, $location, $rootScope, DialogFactory, LoaderDialogFactory, IntercomFactory, ChurnZeroFactory) {
  
  var rememberMe = false;

  function checkCommit() {
    //log users out for each release
    if($cookies.get('commit') !== commit) {
      $cookies.put('commit', commit);
      logout();
      $window.location.reload(true);
    }
  }

  function checkLoggedIn() {
    //check if this is a currently logged in sesion
    var user = getSession();
    
    //check if user has most current user model
    if(user.isadmin === 'undefined') {
      logout();
      return false;
    }
    
    if (user.username) {
      //have to do this to set the $rootscope variables
      setSession(user.user_type, user.trial, user.expiredtrial, user.username, user.email, user.isadmin, user.userid, user.accountid, user.token, user.currentapp);
      
      //need to check if they have cookies saved so we know to update them even though we are prioritizing session data
      var user = getCookies();
      if (user.username) {
        rememberMe = true;
      }
      
      //set raven context
      Raven.setUserContext({
        email: user.username
      });
      
      IntercomFactory.boot(false);
      ChurnZeroFactory.boot()
      
      return true;
    }
    
    //if this is the first time they load the app but they have cookies saved, we need to pull the cookie data
    var user = getCookies();
    if (user.username) {
      renewCookies();
      rememberMe = true;
      setSession(user.user_type, user.trial, user.expiredtrial, user.username, user.email, user.isadmin, user.userid, user.accountid, user.token, user.currentapp);
      
      //set raven context
      Raven.setUserContext({
        email: user.username
      });
      
      return true;
    }
    
    //otherwise you're not logged in, so redirect you to the login page.
    logout();
    return false;
  }

  function logout() {
    IntercomFactory.shutdown();
    ChurnZeroFactory.shutdown();
    Raven.setUserContext();
    deleteSessionAndCookiesAndRootScope();
    DialogFactory.closeDialog();
    LoaderDialogFactory.closeLoaderDialog();
    $rootScope.user = [];
    $rootScope.user.currentapp = null;
    $rootScope.dialog = [];
    $rootScope.dialog.open = false;
    $rootScope.appDeleteDialog = [];
    $rootScope.appDeleteDialog.open = false;
    $rootScope.authDialog = [];
    $rootScope.authDialog.open = false;
    $rootScope.loaderDialog = [];
    $rootScope.loaderDialog.open = false;
    $rootScope.apps = [];
    $rootScope.$evalAsync(function() {
      $location.path('/login/');
    });
  }

function set(user_type, trial, expiredtrial, remember, username, email, isadmin, userid, accountid, token, currentapp) {
    if(remember) {
      rememberMe = true;
      setCookies(user_type, trial, expiredtrial, username, email, isadmin, userid, accountid, token, currentapp);
    } else {
      setSession(user_type, trial, expiredtrial, username, email, isadmin, userid, accountid, token, currentapp);
    }
    IntercomFactory.boot(true);
    ChurnZeroFactory.boot()
  }
  
  function setCurrentApp(currentapp) {
    if(rememberMe) {
      setCookieCurrentApp(currentapp);
    }
    setSessionCurrentApp(currentapp);

  }

  function setType(user_type) {
    if(rememberMe) {
      setCookieType(user_type);
    }
    setSessionType(user_type);
  }

  function setSession(user_type, trial, expiredtrial, username, email, isadmin, userid, accountid, token, currentapp) {
    $window.sessionStorage.setItem('user_type', user_type);
    $window.sessionStorage.setItem('trial', trial);
    $window.sessionStorage.setItem('expiredtrial', expiredtrial);
    $window.sessionStorage.setItem('username', username);
    $window.sessionStorage.setItem('email', email);
    $window.sessionStorage.setItem('isadmin', isadmin);
    $window.sessionStorage.setItem('userid', userid);
    $window.sessionStorage.setItem('accountid', accountid);
    $window.sessionStorage.setItem('token', token);
    $window.sessionStorage.setItem('currentapp', currentapp);
    $rootScope.user.user_type = user_type;
    $rootScope.user.trial = trial;
    $rootScope.user.expiredtrial = expiredtrial;
    $rootScope.user.username = username;
    $rootScope.user.email = email;
    $rootScope.user.isadmin = isadmin;
    $rootScope.user.userid = userid;
    $rootScope.user.accountid = accountid;
    $rootScope.user.token = token;
    $rootScope.user.currentapp = JSON.parse(currentapp);
  }

  function setSessionCurrentApp(currentapp) {
    $window.sessionStorage.setItem('currentapp', currentapp);
    $rootScope.user.currentapp = JSON.parse(currentapp);
  }

  function setSessionType(user_type) {
    $window.sessionStorage.setItem('user_type', user_type);
    $rootScope.user.user_type = user_type;
  }

  function setCookies(user_type, trial, expiredtrial, username, email, isadmin, userid, accountid, token, currentapp) {
    $cookies.put('user_type', user_type);
    $cookies.put('trial', trial);
    $cookies.put('expiredtrial', expiredtrial);
    $cookies.put('username', username);
    $cookies.put('email', email);
    $cookies.put('isadmin', isadmin);
    $cookies.put('userid', userid);
    $cookies.put('accountid', accountid);
    $cookies.put('token', token);
    $cookies.put('currentapp', currentapp);
    setSession(user_type, trial, expiredtrial, username, email, isadmin, userid, accountid, token, currentapp);
  }

  function setCookieCurrentApp(currentapp) {
    $cookies.put('currentapp', currentapp);
  }

  function setCookieType(user_type) {
    $cookies.put('user_type', user_type);
  }

  function getCookies() {
    var user = [];
    user.user_type = $cookies.get('user_type');
    user.trial = $cookies.get('trial');
    user.expiredtrial = $cookies.get('expiredtrial');
    user.username = $cookies.get('username');
    user.email = $cookies.get('email');
    user.isadmin = $cookies.get('isadmin');
    user.userid = $cookies.get('userid');
    user.accountid = $cookies.get('accountid');
    user.token = $cookies.get('token');
    user.currentapp = $cookies.get('currentapp');
    return user;
  }

  function getSession() {
    var user = [];
    user.user_type = $window.sessionStorage.getItem('user_type');
    user.trial = $window.sessionStorage.getItem('trial');
    user.expiredtrial = $window.sessionStorage.getItem('expiredtrial');
    user.username = $window.sessionStorage.getItem('username');
    user.email = $window.sessionStorage.getItem('email');
    user.isadmin = $window.sessionStorage.getItem('isadmin');
    user.userid = $window.sessionStorage.getItem('userid');
    user.accountid = $window.sessionStorage.getItem('accountid');
    user.token = $window.sessionStorage.getItem('token');
    user.currentapp = $window.sessionStorage.getItem('currentapp');
    return user;
  }

  function deleteSessionAndCookiesAndRootScope() {
    $window.sessionStorage.removeItem('user_type');
    $window.sessionStorage.removeItem('trial');
    $window.sessionStorage.removeItem('expiredtrial');
    $window.sessionStorage.removeItem('username');
    $window.sessionStorage.removeItem('email');
    $window.sessionStorage.removeItem('isadmin');
    $window.sessionStorage.removeItem('userid');
    $window.sessionStorage.removeItem('accountid');
    $window.sessionStorage.removeItem('token');
    $window.sessionStorage.removeItem('currentapp');
    $cookies.remove('user_type');
    $cookies.remove('trial');
    $cookies.remove('expiredtrial');
    $cookies.remove('username');
    $cookies.remove('email');
    $cookies.remove('isadmin');
    $cookies.remove('userid');
    $cookies.remove('accountid');
    $cookies.remove('token');
    $cookies.remove('currentapp');
    $rootScope.user = [];
    $rootScope.dialog = [];
  }

  function renewCookies() {
    var user = getCookies();
    $cookies.put('user_type', user.user_type);
    $cookies.put('trial', user.trial);
    $cookies.put('expiredtrial', user.expiredtrial);
    $cookies.put('username', user.username);
    $cookies.put('email', user.email);
    $cookies.put('isadmin', user.isadmin);
    $cookies.put('userid', user.userid);
    $cookies.put('accountid', user.accountid);
    $cookies.put('token', user.token);
    $cookies.put('currentapp', user.currentapp);
  }

  function homepageRedirect() {
    if(checkLoggedIn()) {
      $rootScope.$evalAsync(function() {
        if(($rootScope.user.isadmin === 'false') && ($rootScope.user.user_type.includes("billing")) && (!$rootScope.user.user_type.includes("developer"))) {
          if($location.path() !== '/help-center/') {
            $location.path('/help-center/');
          }
        } else {
          if($location.path() !== '/developer-resources/') {
            $location.path('/developer-resources/');
          }
        }
      });
    } else {
      $location.path('/login/');
    }
  }

  return {
    checkCommit: checkCommit,
    checkLoggedIn: checkLoggedIn,
    logout: logout,
    set: set,
    setCurrentApp: setCurrentApp,
    homepageRedirect: homepageRedirect,
    setType: setType
  }
});
