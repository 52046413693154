angular.module('ControllersModule')
  .controller('AppSettingsController', function ($sce, ChurnZeroFactory, $timeout, $scope, $rootScope, LoginFactory, AccountFactory, AuthenticationFactory, DialogFactory, SDKTypeFactory, $window, $location, $route) {

  $timeout(function() {
    if(!$rootScope.settingAppFromUrl) {
      if($location.path().slice(0,6) === '/apps/') {
        if($rootScope.user.currentapp === null) {
          DialogFactory.openDialog($scope, 'You must select an app to view the content on this page.', false, noAppDialogConfirm);
        }
      }
    }
  });

  function noAppDialogConfirm() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  // https://tc39.github.io/ecma262/#sec-array.prototype.includes
  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      value: function(searchElement, fromIndex) {
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
        var o = Object(this);
        var len = o.length >>> 0;
        if (len === 0) {
          return false;
        }
        var n = fromIndex | 0;
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
        function sameValueZero(x, y) {
          return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
        }
        while (k < len) {
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          k++;
        }
        return false;
      }
    });
  }

  //https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
  // Warn if overriding existing method
  if(Array.prototype.equals)
      console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
  // attach the .equals method to Array's prototype to call it on any array
  Array.prototype.equals = function (array) {
      // if the other array is a falsy value, return
      if (!array)
          return false;

      // compare lengths - can save a lot of time
      if (this.length != array.length)
          return false;

      for (var i = 0, l=this.length; i < l; i++) {
          // Check if we have nested arrays
          if (this[i] instanceof Array && array[i] instanceof Array) {
              // recurse into the nested arrays
              if (!this[i].equals(array[i]))
                  return false;
          }
          else if (this[i] != array[i]) {
              // Warning - two different object instances will never be equal: {x:20} != {x:20}
              return false;
          }
      }
      return true;
  }
  // Hide method from for-in loops
  Object.defineProperty(Array.prototype, "equals", {enumerable: false});

  $scope.account = [];
  $scope.showWarning = false;

  $scope.productName = SDKTypeFactory.getDisplayName($rootScope.user.currentapp.sdk_type) + ' SDK';

  if ($rootScope.user.currentapp.sdk_type === 'legacy') {
    $scope.loadingKey = true;
    loadKey();
  }

  AccountFactory.getAccount().then(getAccountSuccess, getAccountFailure);

  function getAccountSuccess(data) {
    $scope.account = data.result[0];

    if (
      ($rootScope.user.currentapp.sdk_type === 'radius' && !$scope.account.products.includes('radius')) ||
      ($rootScope.user.currentapp.sdk_type === 'legacy' && !$scope.account.products.includes('legacy')) ||
      ($rootScope.user.currentapp.sdk_type === 'sda' && !$scope.account.products.includes('sda')) ||
      ($rootScope.user.currentapp.sdk_type === 'point' && !$scope.account.products.includes('point'))) {
      $scope.showWarning = true
    }
  }

  function getAccountFailure(data) {
    $scope.loadingPage = false;
    $scope.loadingError = true;
  }

  function loadKey() {
    LoginFactory.getAppKey($rootScope.user.currentapp.id).then(loadKeySuccess, loadKeyFailure);
  }

  function loadKeySuccess(data) {
    $scope.key = data.result[0];
    $scope.key.android_key_hash.debug = $scope.key.android_key_hash.debug.filter(Boolean);
    $scope.key.android_key_hash.debug.push("");
    $scope.loadingKey = false;
  }

  function loadKeyFailure(data) {
    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field === '__all__') {
        DialogFactory.openDialog($scope, 'Error: '+message, false, closeDialogLoadKeyFailure);
      } else {
        DialogFactory.openDialog($scope, 'There was an error loading your app. Please try again later.', false, closeDialogLoadKeyFailure);
      }
    } catch (error) {
      DialogFactory.openDialog($scope, 'There was an error loading your app. Please try again later.', false, closeDialogLoadKeyFailure);
    }
  }

  function closeDialogLoadKeyFailure() {
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  $scope.editAppProcessing = false;
  $scope.editAppError = false;
  $scope.editAppErrorMessage = '';
  $scope.editApp = $rootScope.user.currentapp;
  if($rootScope.user.currentapp !== null) {
    $scope.editApp.profiles.sort();
    $scope.originalProfiles = $scope.editApp.profiles.slice();
  }
  var editAppHolder;
  var editKeyHolder;

  function validateBundleID() {
    if($scope.key.apple_bundle_id.length > 0) {
      const regex = /^[A-Za-z0-9\.\-]+$/gm;
      if(regex.exec($scope.key.apple_bundle_id) == null) {
        $scope.editAppErrorMessage = $sce.trustAsHtml('Error: Apple Bundle ID may contain only letters, numbers, periods, and dashes. Information on retrieving your Bundle ID can be found <a href="/developer-resources/ios/apple-bundle-id">here</a>.');
        return false;
      }
    }
    return true;
  }

  function validateKeyHashes() {
    if(($scope.key.android_key_hash.release.length !== 28) && ($scope.key.android_key_hash.release.length > 0)) {
      $scope.editAppErrorMessage = $sce.trustAsHtml('Error: Release key hash must be 28 characters in length. Information on retrieving your key hashes can be found <a href="/developer-resources/android/android-key-hashes">here</a>.');
      $scope.releaseKeyError = true;
      return false;
    }

    //Regex to check if string is a base64 encoded string. From:
    //https://stackoverflow.com/questions/475074/regex-to-parse-or-validate-base64-data
    var regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/gm;
    if(regex.exec($scope.key.android_key_hash.release) == null) {
      $scope.editAppErrorMessage = $sce.trustAsHtml('Error: Release key hash is not valid Base64. Information on retrieving your key hashes can be found <a href="/developer-resources/android/android-key-hashes">here</a>.');
      $scope.releaseKeyError = true;
      return false;
    }

    var decodedDataRelease = window.atob($scope.key.android_key_hash.release);
      if(decodedDataRelease.length != 0 && decodedDataRelease.length != 20) {
          $scope.editAppErrorMessage = $sce.trustAsHtml('Error: Release key hash is not a valid SHA1 signature. Information on retrieving your key hashes can be found <a href="/developer-resources/android/android-key-hashes">here</a>.');
          $scope.releaseKeyError = true;
          return false;
    }

    for(var i=0;i<$scope.key.android_key_hash.debug.length;i++) {
      if(($scope.key.android_key_hash.debug[i].length !== 28) && ($scope.key.android_key_hash.debug[i].length > 0)) {
        $scope.editAppErrorMessage = $sce.trustAsHtml('Error: Debug key hashes must be 28 characters in length. Information on retrieving your key hashes can be found <a href="/developer-resources/android/android-key-hashes">here</a>.');
        $scope.debugKeyErrors[i] = true;
        return false;
      }

      regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/gm;
      if(regex.exec($scope.key.android_key_hash.debug[i]) == null) {
        $scope.editAppErrorMessage = $sce.trustAsHtml('Error: One or more debug key hashes are not valid base64. Information on retrieving your key hashes can be found <a href="/developer-resources/android/android-key-hashes">here</a>.');
        $scope.debugKeyErrors[i] = true;
        return false;
      }

      var decodedDataDebug = window.atob($scope.key.android_key_hash.debug[i]);
      if(decodedDataDebug.length != 0 && decodedDataDebug.length != 20) {
          $scope.editAppErrorMessage = $sce.trustAsHtml('Error: One or more debug key hashes are not valid SHA1 signatures. Information on retrieving your key hashes can be found <a href="/developer-resources/android/android-key-hashes">here</a>.');
          $scope.debugKeyErrors[i] = true;
          return false;
      }
    }

    return true;
  }

  $scope.editAppSubmit = function() {
    $scope.editAppError = false;
    $scope.bundleIdError = false;
    $scope.releaseKeyError = false;
    $scope.debugKeyErrors = [];

    if(!$scope.editAppProcessing) {
      if($scope.editApp.sdk_type === 'legacy') {
        if(validateBundleID() === false) {
          $scope.editAppError = true;
          $scope.bundleIdError = true;
          return;
        }
        if(validateKeyHashes() === false) {
          $scope.editAppError = true;
          return;
        }
        if($scope.editApp.profiles.length === 0) {
          $scope.editAppError = true;
          $scope.editAppErrorMessage = $sce.trustAsHtml('Error: Please select at least one tone profile.');
          return;
        }
      } else if($scope.editApp.sdk_type === 'sda') {
        if($scope.editApp.extra.sda_expiration_delta_seconds === '' ||
          $scope.editApp.extra.sda_expiration_delta_seconds > 172800 ||
          $scope.editApp.extra.sda_expiration_delta_seconds < 1
        ) {
          $scope.editAppError = true;
          $scope.editAppErrorMessage = $sce.trustAsHtml('SDA Expiration Delta Seconds must be an integer between 1 and 172800.');
          return;
        } else if(!Number.isInteger(parseFloat($scope.editApp.extra.sda_expiration_delta_seconds)) || checkOnlyNumbers() == false) {
          $scope.editAppError = true;
          $scope.editAppErrorMessage = $sce.trustAsHtml('SDA Expiration Delta Seconds must be an integer.');
          return;
        }
      }
      edit();
    }
  }

  function checkOnlyNumbers() {
    var isnum = /^\d+$/.test($scope.editApp.extra.sda_expiration_delta_seconds);

    if(isnum) {
      return true;
    } else {
      return false;
    }
  }

  function edit() {
    DialogFactory.closeDialog();
    $scope.editAppProcessing = true;
    AccountFactory.editApp($scope.editApp).then(editAppSuccess, editAppFailure);
  }

  function editAppSuccess(data) {
    editAppHolder = data.result;
    if($scope.editApp.sdk_type === "legacy") {
      var key = {
        apple_bundle_id: $scope.key.apple_bundle_id,
        android_key_hash: {
          release: $scope.key.android_key_hash.release,
          debug: $scope.key.android_key_hash.debug.filter(Boolean)
        }
      };
      LoginFactory.editAppKey($rootScope.user.currentapp.id, $scope.key.id, key).then(editKeySuccess, editAppFailure);
    } else {
      getAppKeySuccess(data);
    }
  }

  function editKeySuccess(data) {
    editKeyHolder = data.result;
    LoginFactory.getAppKey($rootScope.user.currentapp.id).then(getAppKeySuccess, editAppFailure);
  }

  function getAppKeySuccess(data) {
    $rootScope.apps.forEach(function(app, index) {
      if(editAppHolder.id == app.id) {
        $rootScope.apps[index] = editAppHolder;
      }
    });
    $rootScope.user.currentapp = editAppHolder;
    AuthenticationFactory.setCurrentApp(JSON.stringify(editAppHolder));
    if($scope.editApp.sdk_type === "legacy") {
      DialogFactory.openDialog($scope, '<br><img src="/assets/svg/icon-yield-red.svg" style="margin: 0 5px; padding-bottom: 5px; width: 20px;"> App Token Updated <img src="/assets/svg/icon-yield-red.svg" style="margin: 0 5px; padding-bottom: 5px; width: 20px;"><br><br>Making changes to your app’s settings requires updating the App Token in your app. You <span style="font-weight: bold;">must update</span> your SDK with the new App Token for your changes to take effect.<br><br>', false, closeDialog);
    } else {
      DialogFactory.openDialog($scope, '<br><br>Your app has been updated.<br><br>', false, closeDialog);

    }
    $scope.editAppProcessing = false;
  }

  function editAppFailure(data) {
    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field === '__all__') {
        DialogFactory.openDialog($scope, 'Error: '+message, false, closeDialog);
      } else {
        DialogFactory.openDialog($scope, 'There was an error updating your app. Please try again later.', false, closeDialog);
      }
    } catch (error) {
      DialogFactory.openDialog($scope, 'There was an error updating your app. Please try again later.', false, closeDialog);
    }
  }

  function closeDialog() {
    $scope.editAppProcessing = false;
    DialogFactory.closeDialog();
    $route.reload();
  }

  $scope.toggleProfile = function(profile) {
    var index = $scope.editApp.profiles.indexOf(profile);

    if (index === -1) {
      $scope.editApp.profiles.push(profile);
    } else {
      $scope.editApp.profiles.splice(index, 1);
    }

    $scope.editApp.profiles.sort();
  }

  //Delete Methods
  $scope.deleteAppProcessing = false;
  $scope.deleteAppError = false;
  $scope.deleteAppErrorMessage = '';

  $scope.deleteAppSubmit = function() {
    $scope.deleteAppError = false;
    if(!$scope.deleteAppProcessing) {
      DialogFactory.openAppDeleteDialog($scope, deleteApp, deleteAppCancel);
    }
  }

  function deleteApp() {
    $scope.deleteAppProcessing = true;
    AccountFactory.deleteApp($rootScope.user.currentapp).then(deleteAppSuccess, deleteAppFailure);
  }

  function deleteAppCancel() {
    DialogFactory.appDeleteCloseDialog();
    $scope.deleteAppProcessing = false;
  }

  function deleteAppSuccess(data) {
    DialogFactory.appDeleteCloseDialog();
    $scope.deleteAppProcessing = false;

    for(var i=0;i<$rootScope.apps.length;i++) {
      if($rootScope.apps[i].id === $rootScope.user.currentapp.id) {
        $rootScope.apps.splice(i, 1);
        break;
      }
    }

    AuthenticationFactory.setCurrentApp(null);

    DialogFactory.openDialog($scope, 'Your app has been deleted.', false, closeDeleteDialogSuccess);
  }

  function deleteAppFailure(data) {
    DialogFactory.appDeleteCloseDialog();
    try {
      var errorMessage = data.data.result.message;
      var colonArray = errorMessage.split(":");
      for(var i=0;i<colonArray.length;i++) {
        colonArray[i] = colonArray[i].trim();
      }
      var message = colonArray[colonArray.length-1];
      var field = colonArray[colonArray.length-2];
      if(field === '__all__') {
        DialogFactory.openDialog($scope, 'Error: '+message, false, closeDialog);
      } else {
        DialogFactory.openDialog($scope, 'There was an error deleting your app. Please try again later.', false, closeDeleteDialogFailure);
      }
    } catch (error) {
      DialogFactory.openDialog($scope, 'There was an error deleting your app. Please try again later.', false, closeDeleteDialogFailure);
    }
  }

  function closeDeleteDialogFailure() {
    $scope.deleteAppProcessing = false;
    DialogFactory.closeDialog();
  }

  function closeDeleteDialogSuccess() {
    $scope.deleteAppProcessing = false;
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }

  $scope.jwtCopyMessage = '';

  var clipboardJWT = new Clipboard('#token-copy-button');
  clipboardJWT.on('success', function (event) {
    event.clearSelection();
    $scope.jwtCopyMessage = 'Your token has been successfully copied.';
    ChurnZeroFactory.trackEvent('Copied App Token', null, 1, {
      "app_name": $rootScope.user.currentapp.name
    })
  });

  $scope.addDebugHash = function() {
    if($scope.key.android_key_hash.debug.length < 10) {
      $scope.key.android_key_hash.debug.push("");
    }
  }

  $scope.removeDebugHash = function(index) {
    $scope.key.android_key_hash.debug.splice(index,1);
  }

  $scope.showIntegrationType = false;

  $scope.toggleIntegrationType = function(close) {
    if(close) {
      $scope.showIntegrationType = false;
      return;
    }
    $scope.showIntegrationType = !$scope.showIntegrationType;
  }

  $scope.setIntegrationType = function(type) {
    $scope.showIntegrationType = false;
    $scope.editApp.extra.sda_integration_type = type;
  }

  $scope.showTransportType = false;

  $scope.toggleTransportType = function(close) {
    if(close) {
      $scope.showTransportType = false;
      return;
    }
    $scope.showTransportType = !$scope.showTransportType;
  }

  $scope.setTransportType = function(type) {
    $scope.showTransportType = false;
    $scope.editApp.extra.sda_transport_type = type;
  }

  $scope.getBillingTypeDisplay = function(billingType) {
    return AccountFactory.getBillingTypeDisplay(billingType);
  }

  $scope.getToneTransactionTypeDisplay = function(toneTransactionType) {
    return AccountFactory.getToneTransactionTypeDisplay(toneTransactionType);
  }
});
